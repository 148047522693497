import { Template, TemplateConnector } from '@devexpress/dx-react-core';
import {
	FilteringState,
	GroupingState,
	IntegratedFiltering,
	IntegratedGrouping,
	IntegratedSelection,
	IntegratedSorting,
	IntegratedSummary,
	SelectionState,
	SortingState,
	SummaryState,
	TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
	Grid,
	TableBandHeader,
	TableColumnResizing,
	TableFilterRow,
	TableFixedColumns,
	TableGroupRow,
	TableHeaderRow,
	TableSummaryRow,
	VirtualTable,
	TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { Paper } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOneDecimalsAsNumber, validateTableResizing } from 'shared/helpers/general-helpers';
import { setSize } from 'shared/state/ducks/table-settings/operations';
import { TableColumnWidthInfoGeneric } from 'shared/state/ducks/table-settings/types';
import { WebAppState } from 'web/state/store.web';
import { SowListConstants } from 'web/view/components/stem-animal/animal-lists/table-constants';
import { ViewWeb } from 'web/view/components/utils/web-view';
import {
	BandedTableHeaderRowCell,
	GroupCellContent,
	groupRowComponent,
	groupSummaryRowComponent,
	nameGroupCriteria,
	rootComponent,
	SkioldGroupedGenerateIntegratedFilter,
	SkioldGroupedGenerateIntegratedSorting,
	SkioldGroupedGenerateSortingEnabled,
	SkioldGroupedSortLabel,
	SkioldGroupedSummaryCalculator,
	SkioldGroupedTableFilterRowCellComponent,
	SkioldGroupedTableFilterRowRowComponent,
	SkioldTableGroupGridPropsFromParent,
	SummaryRowItem,
	TableHeaderRowCell,
	TableKeyValue,
	totalSummaryRowComponent,
	virtualTableCell,
} from './skiold-table-grouped-grid-helper';
import './skiold-table-grouped-grid.scss';
import { SkioldCheckbox } from '../../skiold-checkbox/skiold-checkbox';
import { WhiteText } from 'web/view/components/Text/white-text';
import { localized } from 'shared/state/i18n/i18n';

const noDataCellComponent = () => <td />;

const getRowId = e => e.id;

export const SkioldTableGroupGrid: FC<SkioldTableGroupGridPropsFromParent> = React.memo(
	(props: SkioldTableGroupGridPropsFromParent) => {
		let notStateFilteredRows = props.data;
		let notStateSelectedRow = [];
		const summaryCellComponent = value => (
			<TableGroupRow.SummaryCell
				{...value}
				onToggle={e => {
					if (value && value.column && value.column && value.column.onSummaryClicked) {
						e.stopPropagation();
						value.column.onSummaryClicked(value.row.key, props.data);
					}
				}}
			/>
		);

		// Set clickable icon if onSummary is set
		const InlineSummaryCellComponent = value => {
			const hasOnSummary = columns.find(e => e.columnName === value.type && e.onSummaryClicked !== undefined);

			// Here summaryMethod returns true instead of a number, this determines what to show
			return hasOnSummary && hasOnSummary.summaryComponent && value.value === true ? (
				hasOnSummary.summaryComponent
			) : value.value ? (
				<>
					{hasOnSummary && <ViewWeb className="folder-arrow arrow-folded" />}
					<div className="summaryItem">{setOneDecimalsAsNumber(value.value)}</div>
				</>
			) : (
				<ViewWeb />
			);
		};
		const dispatch = useDispatch();
		const [columns] = useState(props.columns);
		const [leftFixedColumns] = useState([props.groupBy]);
		const [summaryGroupItems] = useState(
			props.summaryItems
				? props.summaryItems.map(cn => ({
						columnName: cn.columnName,
						type: cn.type ? cn.type : 'sum',
						showInGroupFooter: false,
						alignByColumn: true,
				  }))
				: [],
		);

		const [totalSummaryItems] = useState(
			props.totalSummrayItems
				? props.totalSummrayItems.map(cn => ({
						columnName: cn.columnName,
						type: cn.type ? cn.type : 'sum',
				  }))
				: [],
		);
		const [didFilterChange, setDidFilterChange] = useState<boolean>(true);
		const [expandedGroups, setExpandedGroups] = useState<any[]>(
			props.keepAllExpanded ? props.data.map(item => item[props.groupBy]) : [],
		);
		const [defaultSorting] = useState(props.defaultSortHeaderId ? [props.defaultSortHeaderId] : undefined);
		const [integratedSorting, setIntegratedSorting] = useState(SkioldGroupedGenerateIntegratedSorting(columns));

		const [sortingEnabled, setSortingEnabled] = useState(SkioldGroupedGenerateSortingEnabled(columns));
		const [integratedFilter, setIntegratedFilter] = useState(SkioldGroupedGenerateIntegratedFilter(columns));
		const [summaryItems] = useState(props.summaryItems ? props.summaryItems.map(cn => cn.columnName) : []);
		const [grouping] = useState([{ columnName: props.groupBy }]);
		const [style] = useState({ height: 69 + 53 * (props.rowsToRender ? 5 : 1) });
		const [integratedGroupingColumnExtensions] = useState([
			{ columnName: props.groupBy, criteria: props.groupCritiria ? props.groupCritiria : nameGroupCriteria },
		]);
		const [tableGroupColumnExtension] = useState([{ columnName: props.groupBy, showWhenGrouped: true }]);
		const [groupedColumns] = useState(
			props.groupedHeaders
				? props.groupedHeaders.map(gh => ({
						title: gh.key,
						children: gh.groupColumnNames.map(gcn => ({ columnName: gcn })),
				  }))
				: [],
		);

		const changeFilter = e => {
			setDidFilterChange(true);
			if (props.keepAllExpanded) {
				return;
			}
			setExpandedGroups(e);
		};

		//const [summaryRows, setSummaryRows] = useState<SummaryRowItem[]>([]);

		const tableSettings = useSelector((state: WebAppState) => state.tableSettings.columnWidthsHashMap);

		const changeColumnWidths = (columnWidths: TableColumnWidthInfo[]) => {
			if (props.tableKey) {
				setSize(
					props.tableKey,
					columnWidths.map(e => ({ width: e.width, columnName: e.columnName } as TableColumnWidthInfo)),
				)(dispatch);
			}
		};

		useEffect(() => {
			setIntegratedFilter(SkioldGroupedGenerateIntegratedFilter(columns));
		}, [columns, setIntegratedFilter]);

		useEffect(() => {
			if (!props.keepAllExpanded) {
				return;
			}
			setExpandedGroups(props.data.map(item => item[props.groupBy]));
		}, [props.keepAllExpanded, props.data]);

		useEffect(() => {
			setIntegratedSorting(SkioldGroupedGenerateIntegratedSorting(columns));
		}, [columns, setIntegratedFilter]);

		useEffect(() => {
			setIntegratedSorting(SkioldGroupedGenerateIntegratedSorting(columns));
		}, [columns, setSortingEnabled]);

		const groupRowCell = useCallback(
			value => <TableGroupRow.Cell {...value} expanded={props.keepAllExpanded} />,
			[props.keepAllExpanded],
		);

		// Due to rerender, make a delegatefunction to avoid rerender
		const rootComponentStyle = useMemo(() => rootComponent(style), [style]);
		if (rootComponentStyle === undefined) {
			return null;
		}

		return (
			<ViewWeb className={`borderRadius15 maxWidthFitContent skiold-grouped-table-grid ${props.containerClass}`}>
				<Paper className="transparentBackgroundColor maxWidthFitContent">
					<Grid getRowId={getRowId} rows={props.data} columns={columns} rootComponent={rootComponentStyle}>
						<SortingState columnExtensions={sortingEnabled} defaultSorting={defaultSorting} />
						{!props.sortingDisabled && (
							<>
								<IntegratedSorting columnExtensions={integratedSorting} />
							</>
						)}
						<GroupingState
							grouping={grouping}
							onExpandedGroupsChange={changeFilter}
							expandedGroups={expandedGroups}
						/>
						<IntegratedGrouping columnExtensions={integratedGroupingColumnExtensions} />
						<SummaryState totalItems={totalSummaryItems} groupItems={summaryGroupItems} />
						<IntegratedSummary calculator={SkioldGroupedSummaryCalculator(props.columns)} />

						<FilteringState columnExtensions={integratedFilter} onFiltersChange={changeFilter} />
						<IntegratedFiltering columnExtensions={integratedFilter} />
						{
							// @ts-ignore

							<VirtualTable noDataCellComponent={noDataCellComponent} cellComponent={virtualTableCell} />
						}
						<SelectionState onSelectionChange={props.selectedChanged} selection={props.selections} />
						<IntegratedSelection />
						{props.tableKey && (
							<TableColumnResizing
								minColumnWidth={SowListConstants.iconWidth}
								maxColumnWidth={400}
								onColumnWidthsChange={changeColumnWidths}
								columnWidths={
									(props.tableKey && tableSettings[props.tableKey]
										? validateTableResizing(tableSettings[props.tableKey], props.columns)
										: props.columns) as TableColumnWidthInfo[]
								}
							/>
						)}
						<TableHeaderRow
							showSortingControls={true}
							sortLabelComponent={SkioldGroupedSortLabel(props)}
							cellComponent={TableHeaderRowCell}
						/>
						{props.useSelection && (
							<TableSelection
								selectionColumnWidth={100}
								showSelectAll={true}
								headerCellComponent={(selectionProps: TableSelection.HeaderCellProps) => {
									return (
										props.selectionHeader ?? (
											<TableSelection.HeaderCell
												{...selectionProps}
												onToggle={() => {
													if (props.onHeaderSelectChange) {
														props.onHeaderSelectChange(
															notStateFilteredRows,
															notStateSelectedRow,
														);
													}
												}}
											/>
										)
									);
								}}
								cellComponent={(props: any) => {
									return (
										<td {...props} className={props.classname + ' skiold-table-group-checkbox'}>
											<SkioldCheckbox
												disabled={props.row.disabled}
												isChecked={props.selected}
												onClick={props.onToggle}
											/>
										</td>
									);
								}}
							/>
						)}
						{!props.sortingDisabled && (
							<TableFilterRow
								cellComponent={SkioldGroupedTableFilterRowCellComponent}
								rowComponent={SkioldGroupedTableFilterRowRowComponent}
							/>
						)}
						<TableGroupRow
							formatlessSummaryTypes={summaryItems}
							columnExtensions={tableGroupColumnExtension}
							contentComponent={props.groupCellContent ? props.groupCellContent : GroupCellContent}
							rowComponent={groupRowComponent}
							cellComponent={groupRowCell}
							summaryItemComponent={InlineSummaryCellComponent}
							summaryCellComponent={summaryCellComponent}
						/>
						{<TableBandHeader cellComponent={BandedTableHeaderRowCell} columnBands={groupedColumns} />}
						{totalSummaryItems.length > 0 && (
							<TableSummaryRow
								groupRowComponent={groupSummaryRowComponent}
								messages={props.summaryMessages}
								totalRowComponent={totalSummaryRowComponent}
								formatlessSummaryTypes={props.formatlessSummaryTypes}
							/>
						)}
						<TableFixedColumns leftColumns={leftFixedColumns} />
						<Template name="header">
							<TemplateConnector>
								{({
									rows: filteredRows,
									groupSummaryValues: groupedValues,
									totalSummaryItems: totalItems,
									totalSummaryValues: totalValues,
									groupSummaryItems,
									isGroupRow,
									getCollapsedRows,
									selection,
								}) => {
									let sumRows: SummaryRowItem[] = [];
									let totalSummary: TableKeyValue[] = [];
									let tmpRow: SummaryRowItem | undefined;
									
									//Set on not state properties to prevent rerender
									notStateFilteredRows = filteredRows.filter(r => !isGroupRow(r));
									notStateSelectedRow = selection;

									// Iterate through all rows, grouped and sub-groups
									for (let row of filteredRows) {
										// Check on row is a group row
										if (isGroupRow(row)) {
											const collapsedRows = getCollapsedRows(row);
											// When new Grouped row, add previous to Summary items
											if (tmpRow) {
												sumRows.push(tmpRow);
											}

											// Setup new summary row
											let sumRow = {
												key: row.value,
												summaryValues: [] as TableKeyValue[],
												groupedValues: [] as any[],
											} as SummaryRowItem;

											// Setup Collapsed rows - Used for print
											if (collapsedRows) {
												for (let collapsedRow of collapsedRows) {
													// Set subgroup items
													if (sumRow) {
														sumRow.isExpanded = true;
														sumRow.groupedValues && sumRow.groupedValues.push(collapsedRow);
													} else {
														console.warn('Should never happen');
													}
												}
											}

											// Get summary values
											let grouped = groupedValues[row.key];

											// Map summary key and value
											for (let i = 0; i < groupSummaryItems.length; i++) {
												sumRow.summaryValues &&
													sumRow.summaryValues.push({
														columnKey: groupSummaryItems[i].type,
														columnValue: grouped[i],
													} as TableKeyValue);
											}

											// Set tmp, to add any sub-group items
											tmpRow = sumRow;
										} else {
											// Set subgroup items
											if (tmpRow) {
												tmpRow.isExpanded = true;
												tmpRow.groupedValues && tmpRow.groupedValues.push(row);
											} else {
												console.warn('Should never happen');
											}
										}
									}

									for (let i = 0; i < totalItems.length; i++) {
										totalSummary.push({
											columnKey: totalItems[i].type,
											columnValue: totalValues[i],
										} as TableKeyValue);
									}

									// Makes sure to add last summaryRow item
									if (tmpRow) {
										sumRows.push(tmpRow);
									}
									if (props.tableItemsChange) {
										props.tableItemsChange(sumRows);
									}
									if (props.totalSummaryChanged) {
										props.totalSummaryChanged(totalSummary);
									}

									return <div />;
								}}
							</TemplateConnector>
						</Template>
					</Grid>
				</Paper>
			</ViewWeb>
		);
	},
);
